import React from "react";
import PropTypes from "prop-types";

//styles
import { ColWrapper } from "./styles";

const Col = ({
	children,
	span,
	start,
	row,
	mdSpan,
	mdStart,
	order = null,
	...restProps
}) => {
	// defines end point for grid columns
	const end = span + (start || 0); // desktop
	const mdEnd = mdSpan + (mdStart || 0); // mobile
	return (
		<ColWrapper
			start={start}
			mdStart={mdStart}
			span={end}
			mdSpan={mdEnd}
			row={row}
			order={order}
			{...restProps}
		>
			{children}
		</ColWrapper>
	);
};

Col.propTypes = {
	start: PropTypes.number, // defines column starting point
	mdStart: PropTypes.number, // defines mobile column starting point
	span: PropTypes.number.isRequired, // defines column end point (span value from start)
	mdSpan: PropTypes.number.isRequired, // defines mobile column end point (span value from start)
	row: PropTypes.number, // defines row of the column
	vAlign: PropTypes.oneOf([
		"auto",
		"flex-end",
		"flex-start",
		"self-end",
		"self-start",
		"start",
		"end",
		"center",
		"inherit",
		"initial",
		"normal",
		"stretch",
		"revert",
		"unset",
		"baseline",
	]), // defines vertical align of the column
	hAlign: PropTypes.oneOf([
		"auto",
		"flex-end",
		"flex-start",
		"self-end",
		"self-start",
		"start",
		"end",
		"left",
		"right",
		"center",
		"inherit",
		"initial",
		"normal",
		"stretch",
		"revert",
		"unset",
		"baseline",
	]), // defines horizontal align of the column
	order: PropTypes.oneOf([null, PropTypes.number]),
};
export default Col;
